export const towerHuntGlossary = {
  order: 3,
  name: "Tower Hunt glossary",
  subcategory: "Intro to Tower Hunt",
  markdown: `# Tower Hunt glossary

  This glossary defines common terms that you might see as you're working in Tower Hunt, or throughout our Help Center. 🔍

  {% glossaryAlphabetLinks /%}

  ## A
  #### Above-grade
  Above ground level.
  #### Air rights
  A property right that grants control over the (air)space above a property. In some places, air rights may be transferred to another property. Air rights are typically used to increase the size of a hypothetical land covering's floor area, which is why they are sometimes called unused development rights.
  #### Asset
  In Tower Hunt, a property right or loan.
  #### Assignment
  A legal concept that transfers all of an assignor's rights to an assignee. The assignee effectively takes the assignor's place in the affected contractual relationship.
  ## B
  #### Basis point
  One hundredth of one percent. Abbreviated as "bp" (singular) or "bps" (plural). 100 bps = 1.00%. 525 bps = 5.25%.
  #### Below-grade
  Below ground level.
  #### Big Map
  A view in Tower Hunt where the map is the primary element on-screen. This is the "home" page, and its role is to help you quickly locate the data you need.
  #### Borrower
  The party consuming cash in a financing.
  #### Building
  A land covering that is fully enclosed and may extend above and below ground level.
  #### Building level
  A vertical section of a building that increases its height and contains additional floor area(s). Sometimes called floors or stories.
  #### Buyer
  The party producing cash in a sale.
  ## C
  #### Capital stack
  The layers of funding for a given asset, ordered by their rights to the cash flow.
  #### Cash
  Money that is easily accessible and can be quickly turned into physical notes and coins.
  #### Compact Map
  An expanded version of the Mini Map that fills the width of the primary view.
  #### Company
  In Tower Hunt, an organization with some involvement at a property.
  #### Consensus (validation)
  The collective sentiment about a proposed datapoint after enough validation votes have been submitted. Acceptance and rejection are the two outcomes.
  #### Contact
  In Tower Hunt, a person or company with some involvement at a property.
  #### Contributor
  In Tower Hunt, a user who originates or validates a public datapoint.
  ## D
  #### Datapoint
  In Tower Hunt, an individual piece of information. Some datapoints are directly consumable (ex. the purchase price of a sale, the square footage of a floor area), while others connect datapoints to each other (ex. which investment an ownership interest belongs to, which fee parcel a leashold belongs to). Datapoints can be public or private, free or paid. Datapoints are fundamental to working in Tower Hunt &mdash; they facilitate access to the real estate information you need and enable other key benefits such as revenue sharing, data privacy, and real-time editing.
  #### Deal
  In Tower Hunt, a transaction such as a sale, financing, or lease.
  #### Deal Builder
  A tool in Tower Hunt that displays detailed information about investments. The Deal Builder appears in the sidebar when it is invoked, and the user may close it when it is not needed.
  #### Declassify
  When you convert a private datapoint to a public datapoint.
  ## E
  #### Earnings
  In Tower Hunt, the money or usage credits that a user has accumulated from his/her contributions to the public database.
  #### Enhancement
  In Tower Hunt, a generic term for any upgraded physical attribute that can be attached to a part of a property. Examples include destination dispatch elevators, internal staircases, solar installations, advertising installations, and smart windows. Enhancements are one factor used to determine the relative physical quality of a part of a property.
  ## F
  #### Fee parcel
  A land parcel that is controlled by a fee simple ownership interest.
  #### Fee simple
  A property right that grants perpetual possession of a land parcel with the freedom to dispose of it at will.
  #### Floor area
  A discrete amount of two-dimensional, horizontal space located inside a building. It can be measured and denominated in different ways depending upon its use. Floor area (constructed or hypothetical) helps define the physical size of a building. Constructed floor area can generate revenue if it is leased or licensed.
  #### Financing
  An investment that creates a new loan.
  #### Fully-funded loan amount
  The initial amount of the loan plus any future funding that the lender has agreed to.
  ## G
  #### Ground lease
  A synonym for leasehold.
  ## H
  No glossary items.
  ## I
  #### Interest
  The price a loan borrower pays, most often described as an annual percentage of the amount of the loan.
  #### Investment
  A one-time transaction that produces cash. In Tower Hunt, a sale or financing. One side of the transaction produces cash (ex. buyer, lender) and the other side consumes cash (ex. seller, borrower).
  ## J
  No glossary items.
  ## K
  No glossary items.
  ## L
  #### Land covering
  A structure that covers some part of a land parcel. There are two types of land coverings In Tower Hunt: buildings and surface coverings (ex. parking lots, outdoor storage, solar installations).
  #### Land parcel
  A discrete amount of land. In Tower Hunt, land parcels may or may not correspond to tax parcels.
  #### Lease (floor area)
  A property right that grants the lessee temporary use of certain floor area(s) within the lessor's building. When the lease expires or is terminated, the lessor resumes possession of the floor area(s) and any fixed improvements that have been made. The duration of a lease is typically 1-20 years.
  #### Leasehold (land parcel, condominium, co-op)
  A property right that grants the lessee temporary possession and use of the lessor's piece of the property (ex. land parcel, condominium unit) along with its land coverings. When the leasehold expires or is terminated, the lessor resumes possession and use, inclusive of any land coverings. The duration of a leasehold is typically 25-100+ years.
  #### Lender
  The party producing cash in a financing.
  #### Lessee
  In a leasehold, the party using a piece of the property (ex. land parcel, condominium unit) in exchange for some consideration.
  #### Lessor
  In a leasehold, the party granting temporary use of its piece of the property (ex. land parcel, condominium unit) in exchange for some consideration.
  #### Liquidity event
  A synonym for investment.
  #### Loan
  In Tower Hunt, cash borrowed with the expectation of repayment (typically with interest).
  ## M
  #### Market
  In Tower Hunt, a collection of properties bound by certain criteria such as location, property use, and/or physical quality.
  #### Mini Map
  A small version of the map that appears in the lower-left corner of company, contact, and property pages.
  ## N
  No glossary items.
  ## O
  #### Originator
  In Tower Hunt, a user that authored a datapoint.
  #### Ownership interest
  This entitles a party to collect some portion of an asset's value. In real estate, this usually means the asset's ability to produce regular income via its operation and/or some liquidity event. A 100% interest means the party has undivided ownership of the asset. Partial interests (less than 100%) are common and desirable in real estate for a variety of reasons.
  ## P
  #### Players
  In Tower Hunt, players are companies and contacts serving in various roles at properties.
  #### Property
  Tower Hunt properties act as containers for property rights (ex. fee interests, leaseholds) and structures (ex. buildings, parking lots). Every property has its own page anchored by a property diagram.
  #### Property diagram
  An interactive 2D visualization of a property. The diagram shows the "Y axis", from the sky down to below the ground. The diagram contains horizontal sections for air rights, land coverings (ex. buildings, parking lots), ownership units (ex. condominium units, co-ops), leaseholds, fee parcels, and reciprocal easements.
  #### Property right
  Something that grants the holder some degree of control over one or more parts of a property. Examples include air rights, leaseholds, and fee interests. In Tower Hunt, companies connect to property rights through ownership interests.
  #### Provenance
  The chronology of the ownership of an asset such as a property right or loan. In Tower Hunt, this is tracked via investments.
  ## Q
  No glossary items.
  ## R
  #### Reciprocal easement
  A property right that grants its parties common use of certain parts of a property. In Tower Hunt property diagrams, one or more fee parcels can sit on top of a reciprocal easement to call attention to the shared physical access that is typically being granted.
  #### Rejected (datapoint)
  A status applied to a datapoint that failed validation. Rejected datapoints are still accessible by their authors on a paid basis.
  ## S
  #### Safezone
  An area of the Tower Hunt app where all of your private data can be viewed. In Tower Hunt, "Safezone" means private, and "private" means you are in charge of who has access.
  #### Sale
  An investment that transfers an ownership interest.
  #### Seller
  The party consuming cash in a sale.
  #### Sidebar
  A visual element in Tower Hunt that is separate from and smaller than the main view of a given page. The sidebar displays a variety of information intended to support your main view.
  #### Staking
  Putting money at risk when you create or validate datapoints. Staked money is returned with a bonus for successful outcomes and forfeited for unsuccessful outcomes. {% inlineRouterLink %}Learn more{% /inlineRouterLink %}.
  #### Sublease
  A property right that grants the sublessee temporary use of the sublessor's floor area. Importantly, the sublessor and the lessor are two separate parties. The sublessor is the tenant/lessee in a lease with the lessor (ex. fee owner, leasehold owner). In the absence of an assignment of the original lease, the sublessor is still responsible for its lease obligations to the lessor.
  #### Surface covering
  A land covering that sits at ground level and is not fully enclosed.
  ## T
  No glossary items.
  ## U
  #### Unused development rights
  A synonym for air rights.
  #### Usage credit
  In Tower Hunt, a unit of value that can be exchanged for access to datapoints but may not be withdrawn as cash. Usage credits are earned when free, public datapoints are accessed.
  ## V
  #### Validation
  In Tower Hunt, the process that determines whether a proposed database change becomes part of the public layer. Changes can add or remove data. In either case, successful validation results in reputation increases and monetary rewards to the validated parties. Unsuccessful validation results in reputation decreases and monetary penalties to the rejected parties.
  #### Validator
  In Tower Hunt, a user that votes during validation and whose vote matches the consensus.
  ## W
  No glossary items.
  ## X
  No glossary items.
  ## Y
  No glossary items.
  ## Z
  No glossary items.
  `,
};
